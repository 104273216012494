import { FormikHandlers } from 'formik'
import React, { Ref } from 'react'

interface InputGroupProps {
  additionalContainerClasses?: string
  additionalLabelClasses?: string
  disabled?: boolean
  error?: string
  font?: string
  hideLabel?: boolean
  hideError?: boolean
  inputFontSize?: string
  label: string
  labelTextSize?: string
  max?: number
  placeholder: string
  restrictError?: boolean
  touched?: boolean
  type?: string
  value: string
  width?: string
  required?: boolean
  name?: string
  id?: string
  handleBlur: FormikHandlers['handleBlur']
  handleChange: FormikHandlers['handleChange']
}
const InputGroup = React.forwardRef(
  (
    {
      additionalContainerClasses = '',
      additionalLabelClasses = '',
      error,
      disabled = false,
      font = 'font-ttHovesMedium',
      hideLabel = false,
      hideError = false,
      inputFontSize = 'text-sm',
      label,
      labelTextSize = 'text-sm',
      max = undefined,
      placeholder,
      touched,
      type = 'text',
      value,
      width = 'w-full',
      required,
      name,
      id,
      handleBlur,
      handleChange,
    }: InputGroupProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const breakError = error && error?.length > 75
    return (
      <div
        className={`${additionalContainerClasses} ${width} flex flex-col mb-2`}
      >
        {!hideLabel && (
          <label
            htmlFor={id ? id : undefined}
            className={`${labelTextSize} mb-[7px] text-green-100 ${font} ${additionalLabelClasses}`}
          >
            {label} {required && <span className="">*</span>}
          </label>
        )}
        <input
          ref={ref ? ref : undefined}
          aria-describedby={id ? `${id}_hint` : undefined}
          aria-invalid={error ? 'true' : 'false'}
          className={`rounded-[4px] border border-[#BDBDBD] focus:outline-none focus:ring-2 focus:ring-nptDGreen focus:ring-offset-2 transition-all duration-300 ${inputFontSize}`}
          disabled={disabled}
          id={id ? id : undefined}
          maxLength={max}
          name={name ? name : undefined}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={placeholder}
          type={type}
          value={value}
        />

        {touched && error && (
          <p
            id={id ? `${id}_hint` : undefined}
            className={`${
              !error ? 'hidden opacity-0' : 'opacity-100'
            } flex max-w-[475px] h-3 mt-1 ${
              breakError ? 'mb-4' : ''
            } text-xs text-red-brand`}
          >
            {error}
          </p>
        )}
      </div>
    )
  }
)

export default InputGroup

InputGroup.displayName = 'InputGroup'
