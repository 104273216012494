import Head from 'next/head'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { signIn, useSession, getSession } from 'next-auth/react'

import { useRouter } from 'next/router'
import { useFormik } from 'formik'
import HeadingTwo from '@/components/portal/ui/typography/HeadingTwo'
import BodyOne from '@/components/portal/ui/typography/BodyOne'
import InputGroup from '@/components/forms/elements/InputGroup'
import Button, { ButtonTypeEnum } from '@/components/portal/ui/button'
import * as Yup from 'yup'
import { setLoadingCopy } from '@/utils/portal/setLoadingCopy'
import {
  CognitoIdentityProviderErrorEnum,
  UserRoleEnum,
  cognitoErrorsIndex,
} from '@/types/cognito'
import GoogleIcon from '@/components/shared/GoogleIcon'
import ConfirmMigratedUserReset from '@/components/forms/auth/ConfirmMigratedUserReset'
import { GetServerSidePropsContext } from 'next'
import { event } from '@/utils/gtag'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please use a valid email address')
    .required('Required'),
  password: Yup.string().required('Required'),
})

const Login = () => {
  const { status: sessionStatus } = useSession()
  const [buttonCopy, setButtonCopy] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [isMigratedUser, setIsMigratedUser] = useState(false)
  const router = useRouter()
  const query = router.query

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setError('')
      setLoading(true)
      try {
        const status = await signIn('credentials', {
          redirect: false,
          email: values.email.toLocaleLowerCase(),
          password: values.password,
          callbackUrl: '/portal/account',
        })

        if (status?.ok && status.url) {
          if (process.env.SENTRY_ENV === 'production')
            event({
              action: 'Successful Login',
              category: 'Customer Portal - Login',
              label: 'A user logged into the customer portal',
              value: values.email,
            })

          const session = await getSession()
          if (session?.user?.status === 'reset_password') {
            const hashedCreds = Buffer.from(
              JSON.stringify({
                email: values.email,
                password: values.password,
              })
            ).toString('base64')

            return router.push(`/reset-password/${hashedCreds}`)
          }
          if (session?.user?.role === UserRoleEnum.NOPK_ADMIN) {
            return router.push('/portal/admin')
          }
          return router.push('/portal/account')
        } else {
          if (
            status?.error ===
            CognitoIdentityProviderErrorEnum.MIGRATED_USER_RESET
          ) {
            setIsMigratedUser(true)
            return setLoading(false)
          }
          const error = status?.error?.split(' ')[1]
          setLoading(false)
          if (error && error in cognitoErrorsIndex) {
            return setError(
              cognitoErrorsIndex[error as CognitoIdentityProviderErrorEnum]
            )
          }
          setError('Incorrect email or password. Please try again.')
          console.log('error', status?.error)
        }
      } catch (error) {
        if (process.env.SENTRY_ENV === 'production')
          event({
            action: 'Unsuccessful Login',
            category: 'Customer Portal - Login',
            label: 'A user had an unsuccessful login attempt',
            value: values.email,
          })

        setLoading(false)
        console.log(error)
        setError('There was an error logging in. Please try again.')
      }
    },
  })

  useEffect(() => {
    if (sessionStatus === 'authenticated') router.push('/portal/account')
  }, [sessionStatus])

  useEffect(() => {
    if (loading) {
      setLoadingCopy(setButtonCopy)
    }
  }, [loading])

  useEffect(() => {
    if (query?.error) {
      setError(
        cognitoErrorsIndex[query?.error as CognitoIdentityProviderErrorEnum]
      )
    }
    if (
      query?.message &&
      query?.message === CognitoIdentityProviderErrorEnum.MIGRATED_USER_RESET
    ) {
      setIsMigratedUser(true)
    }
  }, [query])

  return (
    <div className="flex flex-col items-center w-full h-[784px] pt-20 px-2">
      <Head>
        <title>Login | Customer Portal</title>
      </Head>
      {isMigratedUser ? (
        <ConfirmMigratedUserReset email={formik.values.email} />
      ) : (
        <form
          onSubmit={formik.handleSubmit}
          aria-hidden={true}
          onClick={() => setError('')}
          className="flex flex-col items-center justify-center w-full sm:min-w-[320px]"
        >
          <HeadingTwo
            fontSize="text-2xl"
            additionalClasses="my-3 tracking-[0.25%]"
            fontWeight="font-ttHovesBold"
            color="text-green-100"
          >
            Welcome to the NPT customer portal
          </HeadingTwo>

          <div className="w-full sm:w-[322px] mt-4">
            <InputGroup
              id="login_email_field"
              font="font-ttHovesLight"
              label="Email"
              error={formik.errors.email}
              placeholder="you@example.com"
              value={formik.values.email}
              touched={formik.touched.email}
              handleBlur={formik.handleBlur('email')}
              handleChange={formik.handleChange('email')}
            />

            <InputGroup
              id="login_password_field"
              font="font-ttHovesLight"
              type="password"
              label="Password"
              placeholder="password"
              error={formik.errors.password}
              value={formik.values.password}
              touched={formik.touched.password}
              handleBlur={formik.handleBlur('password')}
              handleChange={formik.handleChange('password')}
            />

            <Button
              id="login_button"
              disableHoverShadow
              additionalStyles="font-ttHovesMedium items-center flex justify-center"
              type={ButtonTypeEnum.submit}
              color="bg-green-100"
              width="w-full"
              borderRadius="rounded-[4px]"
              inline
            >
              {loading ? 'Signing In' : 'Sign In'}
              {loading && (
                <span className="min-w-[20px] flex">{buttonCopy}</span>
              )}
            </Button>

            <button
              type={ButtonTypeEnum.button}
              className="bg-white hover:bg-nptGray-100 rounded-[4px] px-8 py-2 text-base font-medium text-npt border text-green-100 border-gray-500
            shadow-sm focus:outline-none focus:ring-2 focus:ring-nptDGreen focus:ring-offset-2 w-full sm:text-sm  transition-all duration-300 p-2 font-ttHovesMedium items-center flex justify-center mt-4"
              onClick={() => {
                setError('')
                signIn('google', {
                  callbackUrl: '/portal/account',
                  redirect: true,
                })
              }}
            >
              <GoogleIcon />
              Sign In With Google
            </button>
          </div>

          <BodyOne additionalClasses="my-4" fontSize="text-regular">
            <Link href="/forgot-password" className="text-green-portal">
              Forgot your password?
            </Link>
          </BodyOne>

          <p className="mt-3 text-red-brand text-regular">{error}</p>

          <BodyOne additionalClasses="mt-8" fontSize="text-regular">
            Don&apos;t have an account?{' '}
            <Link href="/register" className="text-green-portal">
              Register Here
            </Link>
          </BodyOne>
        </form>
      )}
    </div>
  )
}

export default Login

export const getServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  const { req } = context
  const session = await getSession({ req })

  if (session && session?.user?.role === UserRoleEnum.NOPK_ADMIN) {
    return {
      redirect: {
        destination: '/portal/admin',
        permanent: false,
      },
    }
  }

  if (session && session?.user?.userID) {
    return {
      redirect: {
        destination: '/portal/account',
        permanent: false,
      },
    }
  }

  return {
    props: {},
  }
}
