import React from 'react'

interface HeadingTwoProps {
  additionalClasses?: string
  children: React.ReactNode
  color?: string
  fontWeight?: string
  fontSize?: string
}

const HeadingTwo: React.FC<HeadingTwoProps> = ({
  children,
  additionalClasses = '',
  color = 'text-gray-copy',
  fontWeight = 'font-medium',
  fontSize = 'text-xl',
}) => {
  return (
    <h2 className={`${color} ${fontWeight} ${fontSize} ${additionalClasses}`}>
      {children}
    </h2>
  )
}

export default HeadingTwo
