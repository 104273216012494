import BodyOne from '@/components/portal/ui/typography/BodyOne'

const ConfirmMigratedUserReset = ({ email }: { email: string }) => {
  return (
    <div className="w-[572px]">
      <BodyOne
        additionalClasses="text-center"
        fontSize="text-[24px]"
        color="text-[#2D433F]"
        fontWeight="font-ttHovesBold"
      >
        We have a brand new portal!{' '}
      </BodyOne>
      <BodyOne
        additionalClasses="text-center mt-3 leading-[19.5px]"
        fontSize="text-[16px]"
      >
        We look forward to servicing you better with our new experience, however
        existing users are required to reset their password for access to the
        new system.
        <p className="mt-4">
          {' '}
          We have sent you a reset link to your account email at{' '}
        </p>
        {email}.
      </BodyOne>
    </div>
  )
}

export default ConfirmMigratedUserReset
