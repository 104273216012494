export const setLoadingCopy = (
  setCopy: React.Dispatch<React.SetStateAction<string>>
) => {
  const loadingCopy = ['.', '..', '...', '']
  let i = 0

  const interval = setInterval(() => {
    setCopy(loadingCopy[i])
    i++
    if (i >= loadingCopy.length) {
      i = 0
    }
  }, 500)
  return () => clearInterval(interval)
}
