import React from 'react'

interface BodyOneProps {
  additionalClasses?: string
  children: React.ReactNode
  color?: string
  fontWeight?: string
  fontSize?: string
}

const BodyOne: React.FC<BodyOneProps> = ({
  children,
  additionalClasses = '',
  color = 'text-gray-copy',
  fontWeight = 'font-medium',
  fontSize = 'text-sm',
}) => {
  return (
    <p className={`${color} ${fontWeight} ${fontSize} ${additionalClasses}`}>
      {children}
    </p>
  )
}

export default BodyOne
